import React from "react";
import { Link } from "react-router-dom";

const WhyChoose = (data) => {

    return (
        <>
            <div class="marquee hidden overflow-hidden bg-[#D0EFE4] text-[#06184b] text-sm md:text-base lg:text-md sm:text-sm font-bold py-2 relative h-10 my-10">
                <div className="absolute w-[3000px] overflow-hidden">
                    <span><i class="fa-solid fa-bullhorn text-[#6CA30F] mr-[5%]"></i> Join our groups to get in touch with us: <Link to="/" className="text-[#0077FC]">Whatsaap</Link>, <Link to="/" className="text-[#0077FC]">Facebook</Link>, <Link to="/" className="text-[#0077FC]">YouTube</Link> <i class="fa-solid fa-bullhorn text-[#6CA30F] ml-[5%]"></i></span>
                    <span><i class="fa-solid fa-bullhorn text-[#6CA30F] mr-[5%]"></i> Join our groups to get in touch with us: <Link to="/" className="text-[#0077FC]">Whatsaap</Link>, <Link to="/" className="text-[#0077FC]">Facebook</Link>, <Link to="/" className="text-[#0077FC]">YouTube</Link> <i class="fa-solid fa-bullhorn text-[#6CA30F] ml-[5%]"></i></span>
                    <span><i class="fa-solid fa-bullhorn text-[#6CA30F] mr-[5%]"></i> Join our groups to get in touch with us: <Link to="/" className="text-[#0077FC]">Whatsaap</Link>, <Link to="/" className="text-[#0077FC]">Facebook</Link>, <Link to="/" className="text-[#0077FC]">YouTube</Link> <i class="fa-solid fa-bullhorn text-[#6CA30F] ml-[5%]"></i></span>
                </div>
            </div>
            <div className={`px-[5%] w-full mb-10 ${data?.data === 'express' ? 'hidden' : ''}`}>
                <img src="/why-img-1.png" alt="" className="w-full" />
            </div>
        </>
    );
}

export default WhyChoose;