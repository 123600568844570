import React, { useState } from "react";
import { Link } from "react-router-dom";
import PopupForm from './form';

const HomeBanner = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    
    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };


    return (
        <>
            <div className="w-full h-[300px] md:h-[300px] lg:h-auto sm:h-[300px] relative">
                <img 
                    src="/map.png" 
                    alt="" 
                    className="w-full md:w-full lg:w-[60%] sm:w-full m-auto relative opacity-20 h-full object-cover"
                />
                <h1 className="text-lg text-center md:text-xl lg:text-2xl sm:text-lg text-[#06184b] absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[90%] md:w-[90%] lg:w-[60%] sm:w-[90%]">
                    Shopperr is an indian origin cross border ecomm
                    platform that enables access to more than a <span className="text-green-800">Million Products</span> 
                    &nbsp;across <span className="text-red-500">Multiple Categories.</span>
                </h1>
            </div>
            <div className="flex justify-between flex-wrap px-[5%] text-center py-5 gap-y-10">
                <div className="w-[50%] md:w-[18%] lg:w-[18%] sm:w-[50%]">
                    <img src="/icon-1.png" alt="" className="w-10 md:w-16 lg:w-16 sm:w-10 m-auto mb-5" />
                    <p className="text-[#06184b] font-bold text-sm md:text-md lg:text-lg sm:text-sm">International Sourcing</p>
                </div>
                <div className="w-[50%] md:w-[18%] lg:w-[18%] sm:w-[50%]">
                    <img src="/icon-2.png" alt="" className="w-10 md:w-16 lg:w-16 sm:w-10 m-auto mb-5" />
                    <p className="text-[#06184b] font-bold text-sm md:text-md lg:text-lg sm:text-sm">Warehousing & Fulfilment</p>
                </div>
                <div className="w-[50%] md:w-[18%] lg:w-[18%] sm:w-[50%]">
                    <img src="/icon-3.png" alt="" className="w-10 md:w-16 lg:w-16 sm:w-10 m-auto mb-5" />
                    <p className="text-[#06184b] font-bold text-sm md:text-md lg:text-lg sm:text-sm">Logistics</p>
                </div>
                <div className="w-[50%] md:w-[18%] lg:w-[18%] sm:w-[50%]">
                    <img src="/icon-4.png" alt="" className="w-10 md:w-16 lg:w-16 sm:w-10 m-auto mb-5" />
                    <p className="text-[#06184b] font-bold text-sm md:text-md lg:text-lg sm:text-sm">Financing</p>
                </div>
                <div className="w-[50%] md:w-[18%] lg:w-[18%] sm:w-[50%]">
                    <img src="/icon-5.png" alt="" className="w-10 md:w-16 lg:w-16 sm:w-10 m-auto mb-5" />
                    <p className="text-[#06184b] font-bold text-sm md:text-md lg:text-lg sm:text-sm">Dropshipping</p>
                </div>
                <div className="w-full mt-10">
                    <Link className="uppercase bg-[#06184b] py-3 px-5 text-white rounded-lg" onClick={togglePopup}>Get Started</Link>
                </div>
            </div>
            {isPopupOpen && <PopupForm onClose={togglePopup} />}
        </>
    );
}

export default HomeBanner;