import React from "react";

const PrivacyPolicy = () => {
    return (
        <>
            <div className="w-full my-[8%] px-[10%]">
                <h1 className="text-center text-lg text-sm:lg text-md:xl text-lg:2xl text-[#000] uppercase font-bold mb-10">Privacy Policy</h1>
                <p data-mce-fragment="1"><em data-mce-fragment="1">Last Updated: 18<sup data-mce-fragment="1">th</sup> Oct 2024</em></p>
                <p data-mce-fragment="1">&nbsp;</p>
                <h3 data-mce-fragment="1"><strong data-mce-fragment="1">Introduction</strong></h3>
                <p data-mce-fragment="1">Welcome to www.shopperrglobal.com (“we,” “us,” or “our”). We value your privacy and are committed to protecting your personal information. This privacy policy explains how we collect, use, share, and protect your data when you visit or make a purchase from www.shopperrglobal.com (the “Site”).</p>
                <p data-mce-fragment="1">By using our Site, you agree to the terms outlined in this Privacy Policy. Please read this document carefully to understand how we handle your personal information.</p>
                <p data-mce-fragment="1">&nbsp;</p>
                <h3 data-mce-fragment="1"><strong data-mce-fragment="1">Information We Collect</strong></h3>
                <p data-mce-fragment="1">We collect different types of information from and about users of our Site, including:</p>
                <p data-mce-fragment="1">&nbsp;</p>
                <h3 data-mce-fragment="1"><strong data-mce-fragment="1">Personal Information</strong></h3>
                <p data-mce-fragment="1" className="mb-2">When you interact with us, such as creating an account, placing an order, or subscribing to newsletters, we may collect the following personal information:</p>
                <ul data-mce-fragment="1">
                <li data-mce-fragment="1">•	Name</li>
                <li data-mce-fragment="1">• Email address</li>
                <li data-mce-fragment="1">• Shipping and billing addresses</li>
                <li data-mce-fragment="1">• Phone number</li>
                <li data-mce-fragment="1">• Payment information (collected by third-party payment gateways)</li>
                <li data-mce-fragment="1">• Order history and purchase details</li>
                </ul>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">Automatically Collected Information</strong></h3>
                <p>When you browse our Site, we may automatically collect:</p>
                <ol data-mce-fragment="1"></ol>
                <ul data-mce-fragment="1">
                <li data-mce-fragment="1">• IP address</li>
                <li data-mce-fragment="1">• Browser type</li>
                <li data-mce-fragment="1">• Operating system</li>
                <li data-mce-fragment="1">• Usage details (e.g., browsing history, time spent on pages, referring URLs)</li>
                </ul>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">Cookies</strong></h3>
                <p>We use cookies and similar tracking technologies to enhance your shopping experience. These technologies collect certain information automatically and store it in log files.</p>
                <ol data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">You can disable cookies via your browser settings, but doing so may affect the functionality of the Site.</p>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">How We Use Your Information</strong></h3>
                <ol data-mce-fragment="1" start="3"></ol>
                <p data-mce-fragment="1">We use the information we collect in the following ways:</p>
                <ul data-mce-fragment="1">
                <li data-mce-fragment="1">• To process and fulfill your orders, including shipping and returns.</li>
                <li data-mce-fragment="1">• To communicate with you about your orders, promotions, and updates.</li>
                <li data-mce-fragment="1">• To improve and personalize your shopping experience on the Site.</li>
                <li data-mce-fragment="1">• To understand Site usage trends and improve the functionality of the Site.</li>
                <li data-mce-fragment="1">• To comply with legal obligations and resolve disputes.</li>
                </ul>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">How We Share Your Information</strong></h3>
                <ol data-mce-fragment="1" start="4"></ol>
                <p data-mce-fragment="1">We may share your information with third parties in the following scenarios:</p>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">Service Providers</strong></h3>
                <p>We engage third-party companies to assist with certain services, including:</p>
                <ol data-mce-fragment="1"></ol>
                <ul data-mce-fragment="1">
                <li data-mce-fragment="1">• Payment processing</li>
                <li data-mce-fragment="1">• Shipping and delivery</li>
                <li data-mce-fragment="1">• Email communications</li>
                <li data-mce-fragment="1">• Analytics services</li>
                </ul>
                <p data-mce-fragment="1">These third parties have access to personal information only as needed to perform their services and are obligated not to disclose or use it for any other purpose.</p>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">Legal Requirements</strong></h3>
                <p>We may disclose your personal information if required by law, or if we believe that such action is necessary to:</p>
                <ol data-mce-fragment="1"></ol>
                <ul data-mce-fragment="1">
                <li data-mce-fragment="1">• Comply with a legal obligation</li>
                <li data-mce-fragment="1">• Protect and defend our rights or property</li>
                <li data-mce-fragment="1">• Prevent or investigate possible wrongdoing in connection with the Site</li>
                </ul>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">Your Rights</strong></h3>
                <ol data-mce-fragment="1" start="5"></ol>
                <p data-mce-fragment="1">As a user of our Site, you have the following rights regarding your personal information:</p>
                <ul data-mce-fragment="1">
                <li data-mce-fragment="1">• <strong data-mce-fragment="1">Access</strong>: You can request access to the personal information we hold about you.</li>
                <li data-mce-fragment="1">• <strong data-mce-fragment="1">Correction</strong>: You may correct or update any incorrect information.</li>
                <li data-mce-fragment="1">• <strong data-mce-fragment="1">Deletion</strong>: You can request the deletion of your personal data from our records.</li>
                <li data-mce-fragment="1">• <strong data-mce-fragment="1">Opt-Out</strong>: You may opt out of receiving marketing communications by following the unsubscribe link in emails or adjusting your preferences in your account settings.</li>
                </ul>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">Data Security</strong></h3>
                <ol data-mce-fragment="1" start="6"></ol>
                <p data-mce-fragment="1">We implement various security measures to protect your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure. We strive to use commercially acceptable means to protect your personal information but cannot guarantee its absolute security.</p>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">Data Retention</strong></h3>
                <ol data-mce-fragment="1" start="7"></ol>
                <p data-mce-fragment="1">We retain your personal information only for as long as necessary to fulfill the purposes outlined in this privacy policy or as required by law. After that, we securely delete or anonymize your information.</p>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">Children's Privacy</strong></h3>
                <ol data-mce-fragment="1" start="8"></ol>
                <p data-mce-fragment="1">Our Site is not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we learn that we have inadvertently collected personal data from a child, we will take steps to delete it as soon as possible.</p>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">Changes to This Privacy Policy</strong></h3>
                <ol data-mce-fragment="1" start="9"></ol>
                <p data-mce-fragment="1">We may update this privacy policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this page periodically for the latest information.</p>
                <p>&nbsp;</p>
                <h3><strong data-mce-fragment="1">Contact Us</strong></h3>
                <ol data-mce-fragment="1" start="10"></ol>
                <p data-mce-fragment="1">If you have any questions or concerns regarding this privacy policy or how we handle your personal data, please contact us at:</p>
                <ul data-mce-fragment="1">
                <li data-mce-fragment="1"><strong data-mce-fragment="1">Email</strong>: <a href="mailto:support@shopperr.in">support@shopperr.in</a></li>
                <li data-mce-fragment="1"><strong data-mce-fragment="1">Mailing Address</strong>: Plot No. 225, Phase IV, Udyog Vihar, Gurgaon, Haryana. 122001</li>
                </ul>
                <p data-mce-fragment="1" className="mt-5">By using www.shopperrglobal.com, you consent to the practices outlined in this Privacy Policy.</p>
            </div>
        </>
    );
}

export default PrivacyPolicy;