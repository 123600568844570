import React from "react";

const AboutContent = () =>{
    return (
        <>
        <div className="px-[5%] w-full md:w-full lg:w-[60%] sm:w-full m-auto my-10">
            <h2 className="text-[#06184b] text-lg md:text-xl lg:text-4xl sm:text-lg mb-10 text-center font-bold">About Us</h2>
            <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">At Shopperr, we are revolutionizing cross-border trade for Indian resellers and customers. Our mission is to create seamless, hassle-free global commerce by leveraging cutting-edge technology. Based in Gurgaon, India, we empower businesses and consumers to effortlessly access and trade in international markets, breaking down geographical barriers and making the world more connected.</p>
            <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">Our proprietary technology platform is at the heart of what we do. Designed in-house, this platform streamlines the entire cross-border trade process—from product sourcing and inventory management to logistics, payments, and customer service. By automating key steps and integrating with global marketplaces, we enable Indian resellers and customers to trade internationally with ease, efficiency, and confidence.</p>
            <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">Founded and managed by a team of professionals with a passion for global trade, we bring years of expertise in technology, logistics, and market operations to ensure a smooth and reliable cross-border trading experience. Whether you are a reseller looking to expand your inventory with international products or a customer seeking quality goods from around the world, we have you covered.</p>
            <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">With our focus on innovation, we aim to simplify every aspect of cross-border transactions—from sourcing and shipping to payments and customer support—so you can focus on what matters most: growing your business and enjoying global shopping with ease.</p>
            <h2 className="text-[#06184b] text-lg md:text-xl lg:text-4xl sm:text-lg mb-10 text-center font-bold">Why Choose Us?</h2>
            <ul className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">
                <li className="mb-4"><b>Seamless Global Trade:</b> We use state-of-the-art technology to streamline the complexities of international commerce, making it easy and secure.</li>
                <li className="mb-4"><b>Proprietary Tech Platform:</b> Our in-house technology powers every step of the cross-border process, offering unmatched efficiency and reliability.</li>
                <li className="mb-4"><b>Expertise You Can Trust:</b> Our team of professionals brings deep industry knowledge and a commitment to excellence.</li>
                <li><b>Customer-Centric Approach:</b> We prioritize your satisfaction and aim to provide personalized support for a smooth experience.</li>
            </ul>
            <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">Join us on our mission to make the world your marketplace.</p>
        </div>
        </>
    );
}

export default AboutContent;