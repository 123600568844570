import React, { useState, useEffect } from "react";
import Slider from "react-slick";

const Process = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Mobile breakpoint
        };

        // Set initial value
        handleResize();

        // Add event listener to handle window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    
    return (
        <>
        <div className="px-[5%] text-center my-10">
        <h2 className="text-[#06184b] text-xl md:text-xl lg:text-4xl sm:text-xl mb-[5%] font-bold">How it Works?</h2>
        {isMobile ? (
            <Slider {...settings} className="flex justify-between gap-y-10 flex-wrap sm:flex-wrap mt-[10%] mb-[16%]">
                <div className="w-full group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black rounded-[50%]">1</span>
                    <h3 className="hidden text-[#06184b] font-bold text-lg md:text-lg lg:text-2xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Sourcing
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">Place a B2B or B2C order on Shopperr.</p>
                </div>
                <div className="w-full group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black p-5 rounded-[50%]">2</span>
                    <h3 className=" hidden text-[#06184b] font-bold text-lg md:text-lg lg:text-xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Order Processing
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">The order immediately reaches our global Suppliers through our Tech Backbone</p>
                </div>
                <div className="w-full group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black p-5 rounded-[50%]">3</span>
                    <h3 className="hidden text-[#06184b] font-bold text-lg md:text-lg lg:text-xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Warehousing & Fulfilment
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">Order is packed & ready to dispatch within 24-48 hours.</p>
                </div>
                <div className="w-full group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black rounded-[50%]">4</span>
                    <h3 className="hidden text-[#06184b] font-bold text-lg md:text-lg lg:text-xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Order Confirmation & Followup
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">Order is shipped to our Indian warehouse by Flight.</p>
                </div>
                <div className="w-full group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black p-5 rounded-[50%]">5</span>
                    <h3 className="hidden text-[#06184b] font-bold text-lg md:text-lg lg:text-xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Last Mile Delivery
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">Order shipped to customers through Bluedart or Delhivery.</p>
                </div>
                <div className="w-full group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black p-5 rounded-[50%]">6</span>
                    <h3 className="hidden text-[#06184b] font-bold text-lg md:text-lg lg:text-xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Seamless order Tracking
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">Easily track your order on our Tech Platform</p>
                </div>
            </Slider>
        ) : (
            <div className="flex justify-between gap-y-10 flex-wrap sm:flex-wrap">
                <div className="w-[32%] group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black rounded-[50%]">1</span>
                    <h3 className="text-[#06184b] font-bold text-lg md:text-lg lg:text-2xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Sourcing
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">Place a B2B or B2C order on Shopperr.</p>
                </div>
                <div className="w-[32%] group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black p-5 rounded-[50%]">2</span>
                    <h3 className="text-[#06184b] font-bold text-lg md:text-lg lg:text-xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Order Processing
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">The order immediately reaches our global Suppliers through our Tech Backbone</p>
                </div>
                <div className="w-[32%] group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black p-5 rounded-[50%]">3</span>
                    <h3 className="text-[#06184b] font-bold text-lg md:text-lg lg:text-xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Warehousing & Fulfilment
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">Order is packed & ready to dispatch within 24-48 hours.</p>
                </div>
                <div className="w-[32%] group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black rounded-[50%]">4</span>
                    <h3 className="text-[#06184b] font-bold text-lg md:text-lg lg:text-xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Order Confirmation & Followup
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">Order is shipped to our Indian warehouse by Flight.</p>
                </div>
                <div className="w-[32%] group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black p-5 rounded-[50%]">5</span>
                    <h3 className="text-[#06184b] font-bold text-lg md:text-lg lg:text-xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Last Mile Delivery
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">Order shipped to customers through Bluedart or Delhivery.</p>
                </div>
                <div className="w-[32%] group">
                    <span className="mb-5 inline-flex items-center justify-center font-bold w-[48px] h-[48px] bg-[#FFD701] text-base md:text-base lg:text-lg sm:text-base text-black p-5 rounded-[50%]">6</span>
                    <h3 className="text-[#06184b] font-bold text-lg md:text-lg lg:text-xl sm:text-lg my-5 relative w-[max-content] ml-auto mr-auto">
                        Seamless order Tracking
                        <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                    </h3>
                    <p className="text-[#06184b] text-base md:text-base lg:text-lg sm:text-base">Easily track your order on our Tech Platform</p>
                </div>
            </div>
        )}
        </div>
        </>
    );
}

export default Process;