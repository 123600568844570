import React from "react";

const OurTeam = () =>{
    return (
        <>
        <div className="px-[5%] w-full md:w-full lg:w-[60%] sm:w-full m-auto my-10">
            {/* <h2 className="text-[#06184b] text-lg md:text-xl lg:text-4xl sm:text-lg mb-10 text-center font-bold">Our Team</h2> */}
            <img src="team.png" className="w-full" alt="" />
        </div>
        </>
    );
}

export default OurTeam;