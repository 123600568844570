import React from "react";

const TermsCondition = () => {
    return (
        <>
            <div className="w-full my-[8%] px-[10%]">
            <h1 className="text-center text-lg text-sm:lg text-md:xl text-lg:2xl text-[#000] uppercase font-bold mb-10">Terms and Conditions</h1>
                <p data-mce-fragment="1"><em data-mce-fragment="1">Last Updated: 18<sup data-mce-fragment="1">th</sup> Oct 2024</em></p>
                <p data-mce-fragment="1">&nbsp;</p>
                <p data-mce-fragment="1">Welcome to <a href="http://www.shopperrglobal.com" data-mce-fragment="1" data-mce-href="http://www.shopperrglobal.com">www.shopperrglobal.com</a> &amp; <a href="http://www.shopperr.in" data-mce-fragment="1" data-mce-href="http://www.shopperr.in">www.shopperr.in</a>! These Terms and Conditions ("Terms") govern your use of our website <a href="http://www.shopperrglobal.com" data-mce-fragment="1" data-mce-href="http://www.shopperrglobal.com">www.shopperrglobal.com</a>, or <a href="http://www.shopperr.in" data-mce-fragment="1" data-mce-href="http://www.shopperr.in">www.shopperr.in</a> &nbsp;(the "Site") and the purchase of products from our Site. By accessing or using our Site, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Site.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">General</strong></h3>
                <ol data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">These Terms apply to all visitors, users, and customers of the Site. By using our Site and/or purchasing products from it, you agree to comply with and be bound by these Terms, which may be updated from time to time without prior notice. We encourage you to review the Terms periodically.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Use of the Site</strong></h3>
                <ol start="2" data-mce-fragment="1"></ol>
                <p className="pl-5 mb-2" data-mce-fragment="1"><strong data-mce-fragment="1">Eligibility:</strong><br data-mce-fragment="1" /> To use this Site and make purchases, you must be at least 18 years old or have the consent of a parent or legal guardian. By using this Site, you confirm that you meet these requirements.</p>
                <p className="pl-5 mb-2" data-mce-fragment="1"><strong data-mce-fragment="1">Account Registration:</strong><br data-mce-fragment="1" /> To access certain features of the Site, such as placing an order, you may need to create an account. You agree to provide accurate, current, and complete information when registering and to keep your account details secure. You are responsible for any activity that occurs under your account.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Products and Services</strong></h3>
                <ol start="3" data-mce-fragment="1"></ol>
                <p className="pl-5 mb-2" data-mce-fragment="1"><strong data-mce-fragment="1">Product Descriptions:</strong><br data-mce-fragment="1" /> We strive to provide accurate descriptions of the products available on our Site. However, we do not warrant that product descriptions or any other content on the Site is accurate, complete, or error-free. If a product you purchase is not as described, your sole remedy is to return it in unused condition in accordance with our Return Policy.</p>
                <p className="pl-5 mb-2" data-mce-fragment="1"><strong data-mce-fragment="1">Pricing:</strong><br data-mce-fragment="1" /> Prices for products are listed on the Site and are subject to change without notice. While we make every effort to ensure the accuracy of pricing, we reserve the right to correct any errors or discrepancies in pricing.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Order Process and Payment</strong></h3>
                <ol start="4" data-mce-fragment="1"></ol>
                <p className="pl-5 mb-2" data-mce-fragment="1"><strong data-mce-fragment="1">Order Acceptance:</strong><br data-mce-fragment="1" /> Once you place an order, you will receive an order confirmation via email. This email does not signify acceptance of your order but only confirms that we have received your order. We reserve the right to accept, modify, or cancel your order at any time for reasons including, but not limited to, product availability, errors in pricing or product information, or issues with payment processing.</p>
                <p className="pl-5 mb-2" data-mce-fragment="1"><strong data-mce-fragment="1">Payment Methods:</strong><br data-mce-fragment="1" /> We accept various payment methods, including credit cards, debit cards, and third-party payment gateways. All payments must be made at the time of purchase. Your order will not be processed until payment is successfully completed.</p>
                <p className="pl-5 mb-2" data-mce-fragment="1"><strong data-mce-fragment="1">Taxes:</strong><br data-mce-fragment="1" /> Prices listed on the Site are exclusive of taxes unless otherwise noted. You are responsible for paying any applicable taxes on your purchase.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Shipping and Delivery</strong></h3>
                <ol start="5" data-mce-fragment="1"></ol>
                <p className="pl-5 mb-2" data-mce-fragment="1"><strong data-mce-fragment="1">Shipping Policy:</strong><br data-mce-fragment="1" /> We offer shipping to specified locations as detailed in our Shipping Policy. Estimated delivery times are provided for your reference and are not guaranteed. We are not responsible for any delays caused by shipping carriers or factors beyond our control.</p>
                <p className="pl-5 mb-2" data-mce-fragment="1"><strong data-mce-fragment="1">Risk of Loss:</strong><br data-mce-fragment="1" /> Once we hand over the product to the shipping carrier, the risk of loss or damage passes to you. Please refer to our Shipping Policy for more information.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Returns, Refunds, and Cancellations</strong></h3>
                <ol start="6" data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">Please refer to our [Returns, Refund, and Cancellation Policy] for information on how we handle returns, refunds, and order cancellations.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Intellectual Property</strong></h3>
                <ol start="7" data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">All content on the Site, including text, graphics, logos, images, and software, is the property of <a href="http://www.shopperrglobal.com" data-mce-fragment="1" data-mce-href="http://www.shopperrglobal.com">www.shopperrglobal.com</a> or <a href="http://www.shopperr.in" data-mce-fragment="1" data-mce-href="http://www.shopperr.in">www.shopperr.in</a> or its content suppliers and is protected by intellectual property laws. You may not use, reproduce, modify, or distribute any content from the Site without our express written permission.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">User Conduct</strong></h3>
                <ol start="8" data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">You agree to use the Site only for lawful purposes and in a way that does not infringe the rights of others or restrict or inhibit their use of the Site. Prohibited activities include, but are not limited to:</p>
                <ul data-mce-fragment="1">
                <li data-mce-fragment="1">• Posting or transmitting any material that is harmful, defamatory, or unlawful.</li>
                <li data-mce-fragment="1">• Impersonating any person or entity.</li>
                <li data-mce-fragment="1">• Using the Site to send unsolicited promotions, spam, or advertising.</li>
                </ul>
                <p data-mce-fragment="1">We reserve the right to terminate your account or access to the Site if you violate these Terms.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Third-Party Links</strong></h3>
                <ol start="9" data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">Our Site may contain links to third-party websites or services that are not owned or controlled by [Your Website Name]. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services. Your use of such websites is at your own risk, and we recommend that you review their terms and privacy policies.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Disclaimer of Warranties</strong></h3>
                <ol start="10" data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">The Site and all products and services offered through it are provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the Site or any products sold through it, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not guarantee that the Site will be error-free, secure, or uninterrupted.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Limitation of Liability</strong></h3>
                <ol start="11" data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">In no event shall <a href="http://www.shopperrglobal.com" data-mce-fragment="1" data-mce-href="http://www.shopperrglobal.com">www.shopperrglobal.com</a>, <a href="http://www.shopperr.in" data-mce-fragment="1" data-mce-href="http://www.shopperr.in">www.shopperr.in</a>, its directors, employees, or affiliates be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Site or products purchased from it, whether based in contract, tort, strict liability, or otherwise, even if we have been advised of the possibility of such damages.</p>
                <p data-mce-fragment="1">Our total liability for any claim arising from or related to your use of the Site or products purchased from it shall not exceed the amount paid by you for the product in question.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Indemnification</strong></h3>
                <ol start="12" data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">You agree to indemnify, defend, and hold harmless [Your Website Name], its affiliates, and their respective directors, officers, employees, and agents from any claims, liabilities, damages, losses, or expenses arising out of your use of the Site or your violation of these Terms.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Governing Law</strong></h3>
                <ol start="13" data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions. Any disputes arising from or relating to these Terms or your use of the Site shall be subject to the exclusive jurisdiction of the courts in Haryana.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Changes to the Terms</strong></h3>
                <ol start="14" data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">We reserve the right to modify these Terms at any time without prior notice. Your continued use of the Site after any changes to the Terms constitutes your acceptance of the new Terms. We encourage you to review these Terms periodically for updates.</p>
                <p>&nbsp;</p>
                <h3 className="mb-2 text-lg"><strong data-mce-fragment="1">Contact Us</strong></h3>
                <ol start="15" data-mce-fragment="1"></ol>
                <p data-mce-fragment="1">If you have any questions or concerns about these Terms, please contact us at:</p>
                <ul data-mce-fragment="1">
                <li data-mce-fragment="1"><strong data-mce-fragment="1">Email</strong>: <a href="mailto:support@shopperr.in">support@shopperr.in</a></li>
                </ul>
                <p data-mce-fragment="1" className="mt-5">By using <a href="http://www.shopperr.in" data-mce-fragment="1" data-mce-href="http://www.shopperr.in">www.shopperr.in</a> or <a href="http://www.shopperrglobal.com" data-mce-fragment="1" data-mce-href="http://www.shopperrglobal.com">www.shopperrglobal.com</a>, you acknowledge that you have read, understood, and agree to these Terms and Conditions.</p>
            </div>
        </>
    );
}

export default TermsCondition;