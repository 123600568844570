import React, { useState } from "react";
import { Link } from "react-router-dom";
import PopupForm from './form';

const PlusDirect = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    
    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    return (
        <>
            <div className="bg-[#06184b] px-[5%] py-[5%] my-[5%] text-center">
                <h2 className="text-[#62c8f5] text-xl md:text-xl lg:text-4xl sm:text-xl mb-5 font-bold">Shopperr Direct</h2>
                <p className="text-white text-base md:text-base lg:text-lg sm:text-base mb-5 w-full md:w-full lg:w-[50%] sm:w-full m-auto"> 
                Sell on Amazon, Flipkart, Meesho or your shopify website with our huge range of products without owning any stock.
                </p>
                <Link onClick={togglePopup} className="inline-block text-white bg-[#48AF1F] mx-2 px-[3%] py-3 rounded-lg uppercase">Lets Start</Link>
                <Link to="/" className="inline-block text-white bg-[#48AF1F] mx-2 px-[3%] py-3 rounded-lg uppercase">View Dropship Products</Link>
            </div>
            <div className="px-[5%] w-full">
                <img src="plus-direct-img-1.png" alt="" className="w-full" />
            </div>
            {isPopupOpen && <PopupForm onClose={togglePopup} />}
        </>
    );
}

export default PlusDirect;