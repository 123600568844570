import React from "react";
import AboutContent from "../component/aboutcontent";
import OurTeam from "../component/ourteam";
import { Link } from "react-router-dom";

const AboutUs = () => {
    return (
        <>
            <div className="flex gap-3 items-center text-[#06184b] text-sm md:text-sm lg:text-base sm:text-sm px-[5%]">
                <Link to="/">Home</Link>
                <svg class="theme-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79289 6.20711L5 2.41421L6.41421 1L11.6213 6.20711L6.41421 11.4142L5 10L8.79289 6.20711Z" fill="#06184b"></path>
                </svg>
                <p>About Us</p>
            </div>
            <AboutContent />
            <OurTeam />
        </>
    );
}

export default AboutUs;