import React from "react";
import HomeBanner from "../component/homebanner";
import WhyChoose from "../component/whychoose";
// import JoinUs from "../component/joinus";
// import WhereSell from "../component/wheresell";
import Process from "../component/process";
// import Client from "../component/client";

const HomePage = () => {
    return (
        <>
            <HomeBanner />
            <WhyChoose />
            {/* <JoinUs /> */}
            <Process />
            {/* <WhereSell /> */}
            {/* <Client /> */}
        </>
    );
}

export default HomePage;